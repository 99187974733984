<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" class="cur-a">培训管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          "
          style="align-items: flex-start"
        >
          <div class="searchbox" style="width: 100%;margin-bottom: 10px;">
            <div>
              <div title="任务名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">任务名称:</span>
                <el-input
                  v-model="ruleForm.projectName"
                  type="text"
                  size="small"
                  placeholder="请输入任务名称"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div title="任务状态" class="searchboxItem searchboxItems">
                <span class="itemLabel">任务状态:</span>
                <el-select
                  size="small"
                  v-model="ruleForm.projectState"
                  placeholder="请选择任务状态"
                  clearable
                >
                  <el-option
                    v-for="item in ClassstatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div title="发布状态" class="searchboxItem searchboxItems">
                <span class="itemLabel">发布状态:</span>
                <el-select
                  size="small"
                  v-model="ruleForm.taskStatus"
                  placeholder="请选择发布状态"
                  clearable
                >
                  <el-option
                    v-for="item in taskStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="searchbox" style="width: 100%">
            <div>
              <div title="创建时间" class="searchboxItem searchboxItems">
                <span class="itemLabel">创建时间:</span>
                <el-date-picker
                  clearable
                  size="small"
                  v-model="ruleForm.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
              <div title="培训类型" class="searchboxItem searchboxItems">
                <span class="itemLabel">培训类型:</span>
                  <el-cascader
                    v-model="ruleForm.trainTypeId"
                    :options="TrainTypeList"
                    clearable
                    filterable
                    :show-all-levels="false"
                    :props="{
                      emitPath: false,
                      value: 'id',
                      label: 'label',
                      children: 'children',
                      checkStrictly: true,
                    }"
                    size="small"
                  ></el-cascader>
              </div>
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="
                  () => {
                    getData();
                  }
                "
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addTraining()"
                >新建培训任务</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader4"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="培训编号"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200"
                fixed="left"
              />
              <el-table-column
                label="任务名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="220"
                fixed="left"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                min-width="150px"
              />
              <el-table-column
                label="关联人数"
                align="left"
                show-overflow-tooltip
                prop="relationNum"
                min-width="100px"
              />
              <el-table-column
                label="参与人数"
                align="left"
                show-overflow-tooltip
                prop="partakeNum"
                min-width="100px"
              />
              <el-table-column
                label="完成人数"
                align="left"
                show-overflow-tooltip
                prop="completeNum"
                min-width="100px"
              />
              <el-table-column
                label="有效日期"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="150"
              >
              <template slot-scope="{ row }">
                  {{row.startDate + '-' + row.endDate}}
              </template>
              </el-table-column>
              <el-table-column
                label="任务状态"
                align="left"
                show-overflow-tooltip
                prop="taskStatus"
                min-width="100px"
              />
              <el-table-column
                label="发布状态"
                align="left"
                show-overflow-tooltip
                prop="releaseStatus"
                min-width="100px"
              />
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="150px"
              />
              <el-table-column
                label="操作"
                align="center"
                width="360px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="addTraining(scope.row)"
                      >详情</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="jumpclassList(scope.row)"
                      >课程列表</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="jumpStudentList(scope.row)"
                      >学员列表</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      :disabled="scope.row.auditState=='30'"
                      @click="releaseATask(scope.row)"
                      >发布任务</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      :disabled="scope.row.auditState=='30'"
                      @click="deleteproject(scope.row.projectId)"
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增培训任务 - 弹框 -->
    <el-dialog
      :title="dialogData.dialogTitle"
      :visible.sync="dialogData.dialogState"
      :close-on-click-modal="false"
      size="mini"
      width="600px"
    >
      <el-form
        ref="dialogData"
        :model="dialogData"
        label-width="100px"
        :rules="detailRules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="任务名称" prop="projectName" label-width="120px">
              <el-input
                v-model="dialogData.projectName"
                placeholder="请输入任务名称"
                size="small"
                clearable
                :maxlength="30"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="有效期" prop="periodTime" label-width="120px">
              <el-date-picker
                clearable
                size="small"
                v-model="dialogData.periodTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="培训类型" prop="trainTypeId" label-width="120px">
              <el-cascader
                v-model="dialogData.trainTypeId"
                :options="TrainTypeList"
                clearable
                filterable
                :show-all-levels="false"
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                size="small"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="培训人数" prop="projectPeople" label-width="120px">
              <el-input-number 
                v-model="dialogData.projectPeople"
                :controls="false"
                :min="1" 
                />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="dialogData.dialogState = false"
          size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="addTrainingConfirm" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classListCtr",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      ruleForm: {
        projectName: "",//培训任务名称
        projectState: "",//培训任务状态
        createTime: "",//创建时间
        trainTypeId: "",//培训类型
        taskStatus: "",//发布状态
      },
      params: {},
      TrainTypeList: [],//培训类型list
      taskStatusList: [
        {
          label: "未发布",
          value: "10"
        },
        {
          label: "已发布",
          value: "30"
        },
      ],//发布状态list
      ClassstatusList:[
        {
          label: "未开始",
          value: "10"
        },
        {
          label: "进行中",
          value: "20"
        },
        {
          label: "已结束",
          value: "30"
        }
      ],//培训任务状态
      dialogData:{
        dialogTitle:'',
        dialogState: false, // dialog状态
        projectName: null,//培训任务名称
        periodTime: null,//有效期
        trainTypeId: null,//培训类型
        projectPeople: null,//培训人数
      },
      detailRules: {
        projectName: [
          { required: true, message: "请输入培训任务名称", trigger: "blur" },
        ],
        periodTime: [
          { required: true, message: "请输入有效期", trigger: "blur" },
        ],
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        projectPeople: [
          { required: true, message: "请输入培训人数", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getTableHeight();
    // this.getclassstatusList();
    this.getTrainTypeArrData();
  },
  computed: {},
  mounted() {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 培训任务名称
      if (this.ruleForm.projectName) {
        params.projectName = this.ruleForm.projectName.trim();
      }
      // 培训任务状态
      if (this.ruleForm.projectState) {
        params.projectState = this.ruleForm.projectState;
      }
      // 创建时间
      if (this.ruleForm.createTime&&this.ruleForm.createTime.length===2) {
        params.startDate = this.ruleForm.createTime[0];
        params.endDate = this.ruleForm.createTime[1];
      }
      // 培训类型
      if (this.ruleForm.trainTypeId) {
        params.trainTypeId = this.ruleForm.trainTypeId;
      }
      // 发布状态
      if (this.ruleForm.taskStatus) {
        params.taskStatus = this.ruleForm.taskStatus;
      }
      
      this.doFetch(
        {
          url: "/biz/et/trainTask/trainTaskList",
          params,
          pageNum,
        },
        true
      );
    },
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    //获取任务状态数据
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.ClassstatusList = list;
    },
    //删除
    deleteproject(projectId) {
      this.doDel({
        url: "/biz/et/trainTask/deleteTrainTask",
        msg: "你确定要删除该培训任务吗？",
        ps: {
          type: "post",
          data: { projectId },
        },
      });
    },
    //课程列表
    jumpclassList(row) {
      this.$router.push({
        path: "/azhr/humanResources/trainingManagement/ClassdetailsList",
        query: {
          active: "second",
          projectId: row.projectId,
          projectName: row.projectName,
        },
      });
    },
    // 发布任务
    releaseATask(row){
      if (row.courseCount == 0) {
        this.$confirm("您的培训任务还未配置课程,请先配置课程！", "提示", {
          confirmButtonText: "配置课程",
          cancelButtonText: "取消",
          confirmButtonClass: "confirmButtonClass",
          type: "warning",
        })
        .then(() => {
          this.jumpclassList(row)
        })
        .catch(() => {});
        return
      }
      this.$confirm("是否发布培训任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "confirmButtonClass",
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/et/trainTask/releaseTrainTask", {
            projectId: row.projectId,
          })
            .then((ret) => {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.getData();
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {});
    },
    //跳转学员列表
    jumpStudentList(row) {
      this.$router.push({
        path: "/azhr/humanResources/trainingManagement/ClassdetailsList",
        query: {
          active: "three",
          projectId: row.projectId,
          projectName: row.projectName,
        },
      });
    },
    // 新增培训任务
    addTraining(row) {
      this.emptyData();
      console.log(row);
      if (row) {
        Object.keys(this.dialogData).forEach((key) => {
          this.dialogData[key] = row[key];
        });
        this.dialogData.periodTime = [row.startDate.replaceAll('/','-'),row.endDate.replaceAll('/','-')]
        this.dialogData.dialogTitle = "编辑培训任务";
        this.dialogData.projectId = row.projectId; //id
      } else {
        this.dialogData.dialogTitle = "创建培训任务";
        this.dialogData.projectId = ""; //id
      }
      this.dialogData.dialogState = true;
    },
    // 新增/编辑 - 确认
    addTrainingConfirm() {
      this.$refs.dialogData.validate((valid) => {
        if (valid) {
          this.dialogData.startDate = this.dialogData.periodTime[0]
          this.dialogData.endDate = this.dialogData.periodTime[1]
          let url
          if(this.dialogData.projectId){
            url = '/biz/et/trainTask/updateTrainTask'
          }else{
            url = '/biz/et/trainTask/addTrainTask'
          }
          this.$post(url, this.dialogData)
            .then((res) => {
              if (res.status == 0) {
                this.dialogData.dialogState = false;
                this.$message.success(res.message);
                this.getData(1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 80 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    emptyData() {
      this.dialogData = {
        dialogTitle:'创建培训任务',
        dialogState: false, // dialog状态
        projectName: null,//培训任务名称
        periodTime: null,//有效期
        trainTypeId: null,//培训类型
        projectPeople: null,//培训人数
      };
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if(from.path == '/web/CustomPrograms/trainingList/ClassdetailsList'){
        vm.getData()
      }
    })
  }
};
</script>
<style lang="less">
//.el-table__body-wrapper {
//  z-index: 2;
//}
</style>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}

.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
.el-dialogs {
  .el-cascader {
    width: 100%;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}

//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
  background-color: #eee;
  border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
/deep/.el-pager li{
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
  background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
/deep/.el-pager li.active+li{
  border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
  border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
  //color: #2878FF;
  font-size: 14px!important;
}
/deep/.bgc-bv{
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{

}
/deep/.el-table th.el-table__cell>.cell{
  font-size: 14px!important;
  font-weight: 400!important;
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
  border-color: #2878FF !important;
}
</style>
